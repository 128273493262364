import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { Link } from 'react-router-dom'
import MenuList from '../MenuList';
import NavMenu from './NavMenu'




const HeaderOne = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='w-full flex justify-center relative bg2'>
            <div className='md:flex w-[90%] justify-between items-center hidden'>

                <nav className='w-[40%] flex justify-end'>
                    <div className='relative flex mr-10 '>
                        <MenuList classes={'text-white'} rangoItemsFin={3} />
                    </div>

                </nav>
                <div className='w-[15%]  relative pr-3 md:pr-0 mb-5 h-20'>
                    <Link to={'/'}>
                        <img
                            src={`${rpdata?.dbPrincipal?.logo}`}
                            alt='no found'
                            className='w-[150px] h-[150px] p-5 absolute top-5 left-5 bg2 rounded-full z-20'
                        />
                    </Link>
                </div>

                <nav className='w-[40%] flex justify-start'>
                    <div className='relative flex ml-10 '>
                        <MenuList classes={'text-white'} rangoItemsInicio={3} />
                    </div>

                </nav>
            </div>
            <div className='w-full flex items-center md:hidden justify-start relative h-auto'>
                <div className='w-1/2 h-auto absolute top-0 right-0'>
                    <Link to={'/'}>
                        <img
                            src={`${rpdata?.dbPrincipal?.logo}`}
                            alt='no found'
                            className='w-[180px] h-[150px] p-5 absolute top-5 left-5 bg2 rounded-full z-20'
                        />
                    </Link>
                </div>
                <div className='py-10 w-auto pl-10'>
                <nav>
                    <NavMenu />
                </nav>
            </div>
            </div>
           
        </div>
    )
}

export default HeaderOne