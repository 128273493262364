
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal } from "react-icons/gi";
// import { AiOutlineTeam, AiOutlineHome } from "react-icons/ai";
// import { TbPlant2 } from "react-icons/tb";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <div className="flex w-full bg-2">
      <div className="pic-wrapper w-full h-screen " >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-[4] relative w-full h-full flex md:justify-start justify-center md:pb-[170px] md:pt-[200px] pt-[180px] pb-[100px]">
          <div className="md:w-1/2 w-[90%] md:ml-32">
            <div className="w-full flex flex-col md:justify-start">
              <h1 className="text-white text-center md:text-start text-[35px] md:text-[50px]">
                {title}
              </h1>
              <p className="text-white text-center md:text-start ">
                {texts}
              </p>
            </div>
            <div className="w-full flex justify-center md:justify-start py-5">
              <ButtonContent btnStyle={'five'} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default HeroSection;
